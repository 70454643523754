@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* @layer Navbar {
  .flex-cs {
    @apply flex items-center justify-start gap-4;
  }
} */
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Montserrat:wght@600&family=Ubuntu:ital@1&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Elsie:wght@400;900&display=swap');


.dot-grid {
  background-image: repeating-linear-gradient(
    0deg,
    transparent,
    transparent 3px,
    black 3px,
    black 6px
  );
}

/* 
@layer utilities {
  @variants responsive {

    Hide scrollbar for Chrome, Safari and Opera
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    Hide scrollbar for IE, Edge and Firefox
    .no-scrollbar {
      -ms-overflow-style: none;
      IE and Edge
      scrollbar-width: none;
      Firefox
    }
  }
}
*/

@layer utilities {
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
